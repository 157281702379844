import Summary from './components/summary'

document.addEventListener("DOMContentLoaded", function (event: Event) {
    const viewButton: HTMLElement = document.getElementById('spViewSwitch')
    const optionsButton: HTMLElement = document.getElementById('spOptionsViewSwitch')
    const tourConfigLink: HTMLLinkElement = document.querySelector('.sp-tour-configuration-link')
    const searchPanel: HTMLElement = document.getElementById('search-panel-container')
    const optionsPanel: HTMLElement = document.getElementById('options-panel-container')
    const summaryPanel: Summary = new Summary('summary-panel-container')

    let switchButton: HTMLElement
    let switchCallback: () => any

    // Set summary actual interaction
    if (viewButton) {
        switchButton = viewButton
        switchCallback = (): void => {
            searchPanel.classList.remove('sp-hide')
            searchPanel.removeAttribute('aria-hidden')
            searchPanel.removeAttribute('hidden')
        }


    } else if (optionsButton) {
        switchButton = optionsButton
        switchCallback = (): void => {
            optionsPanel.classList.remove('sp-hide')
            optionsPanel.removeAttribute('aria-hidden')
            optionsPanel.removeAttribute('hidden')
      }
    }

    // Make interaction work
    summaryPanel.setCallback(switchCallback)
    switchButton && switchButton.addEventListener('click', switchCallback)

    /*
    * Tours Only:
    * This link scrolls the Tours Options page to the Room Configuration section with ID 'slide-down-room'
    * */
    if (tourConfigLink) {
        tourConfigLink.addEventListener('click', (e: Event) => {
            const targetElementId = 'slide-down-room'
            let target = document.getElementById(targetElementId)

            target && target.scrollIntoView({
                behavior: "smooth",
                block: "start"
            })
        })
    }
})