export default class Summary {
  readonly id: string
  private container: HTMLElement
  private switchActions: NodeList
  private switchCallBack: () => any
  private SUMMARY_ACTION_CLASS = 'sp-summary'

  constructor(id: string) {
    try {
      this.id = id
      this.container = document.getElementById(id)
      this.switchActions = this.container && this.container.querySelectorAll(`.${this.SUMMARY_ACTION_CLASS}`)
    } catch (e) {
      console.error('Summary panel cannot be initialised', e)
    }
  }

  private hidePanel(): void {
    if (!this.container) return

    this.container.classList.add('sp-hide')
    this.container.setAttribute('aria-hidden', 'true')
    this.container.setAttribute('hidden', 'true')
  }

  // Set summary panel interaction callback for all clickable elements
  public setCallback(callback: () => any): void {
    if (!this.switchActions) return

    this.switchCallBack = callback

    for (let i = 0; i < this.switchActions.length; i++) {
      this.switchActions[i].addEventListener('click', (e: Event) => {
        this.hidePanel()

        if (typeof this.switchCallBack === 'function') {
          this.switchCallBack()
        }
      })
    }
  }
}